<template>
    <div>
        <v-container fluid pa-0>
            <v-row align="center" justify="center" style="height:100vh" dense>
                <v-col cols="12" lg="6" md="6" class="fill-height d-flex flex-column justify-center align-center">
                    <v-card elevation="4" style="border-radius:10px">
                        <v-list>
                            <v-list-item>
                                <v-btn text large @click="backReturnUrl"><v-icon size="45px" left color="primary"> mdi-chevron-left</v-icon>Back</v-btn>
                                <v-list-item-action>
                                    <v-list-item-title class="unauthorized">You are not Authorized</v-list-item-title>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                        <v-img
                            :src="require('../assets/unauthorized.jpg')"
                            alt="Unauthorized Image"
                            elevation="5"
                            eager
                        ></v-img>
                    </v-card>
                </v-col> 
            </v-row>
        </v-container>
    </div>
</template>


<style scoped>
    .unauthorized{
        font-size: 22px !important;
        color: red;
        font-weight: bold;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        font-style:normal;
    }
</style>


<script>

export default {
    
    props: ['sp_return_url'],
    data: () => ({
       
       //data variables
    }),

    methods:{
        
        backReturnUrl(){
            //console.log(this.sp_return_url);
            window.location.href = this.sp_return_url;
        }, 
    }
}
</script>