<template>
  <v-app class="page_bg">

    <div class="main_div" v-if="isMainDiv">
      <v-card 
        class="mx-auto"
        max-width="450"
        style="margin-top:100px;border-radius:15px"
      >

      <!-- Show response message popup/dialog box -->
      <v-snackbar
        v-model="snackbar"
        :color="color"
        :multi-line="mode === 'multi-line'"
        :timeout="timeout"
        :top="true"
        :vertical="mode === 'vertical'"
        rounded="pill"
      >
        {{ popup_text }}
      <v-btn text @click="snackbar = false" style="color:#ffffff;">Close</v-btn>
      </v-snackbar>

      <!-- Logo of the merchant -->
      <div class="avatar_div">
        <v-card-actions class="justify-center">
          <v-avatar size="100px" style="margin-top:-56px;" class="justify-center avatar_img" >
          <v-img
              :src="merchant_logo"
              alt="merchant logo"
              title="merchant logo"
              style="background-color:#fff;width:100px"
              contain
          ></v-img>
          </v-avatar>
        </v-card-actions>
      </div>
      
      <!-- Payment ,client name,Taka amount,order_id section-->
      <div class="pay_amount_main_div">
        <v-container style="margin-top:-20px;">
          <v-row style="height:60px;margin-top:-10px;">
            <v-col md="6" sm="6" xs="12">
              <p class="payment_to">Payment to</p>
              <p class="client_name">{{merchant_name}}</p>
            </v-col>

            <v-col md="6" sm="6" xs="12" class="border_left">
              <p class="payment_amount">{{sp_amount}} &nbsp;&nbsp;&nbsp;<span style="font-size:13px">{{sp_currency}}</span></p>
              <p class="order_id"><span style="font-weight:bold">Order ID</span>: {{sp_orderid}}</p>
            </v-col>
          </v-row>
        </v-container>
      </div>
      
      <!-- Tabs or Nav section start-->
      <div style="background-color:#fff;margin:5px">
        <v-card elevation="0">
          <v-tabs
            v-model="active_tab"
            background-color="#fff"
            dark
            centered
            icons-and-text
            height="55px"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#cards" class="tabs_text_style" id="card_style" @click="show_cards()"
             style="width:33.33%" >CARDS
              <v-icon size="22px" style="color:#fff">mdi-credit-card-multiple</v-icon>
            </v-tab>
            
            <v-tab href="#mfs" class="tabs_text_style" id="mbanking_style" @click="show_mbanking()"
              style="width:33.33%">mBANKING
              <v-icon  size="22px" style="color:#fff">mdi-cellphone</v-icon>
            </v-tab>
            
            <v-tab href="#ibanking" class="tabs_text_style" id="ibanking_style" @click="show_ibanking()"
              style="width:33.33%">iBANKING 
              <v-icon  size="22px" style="color:#fff">mdi-bank</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="active_bar">
          
           <v-tabs-items v-if="saved_card_tab1" >
              <v-container>
                <div style="height:41px;" v-if="hidecardmenu">
                  <ul style="list-style-type: none;">
                    <li style="float:left;">
                      <v-img
                        :src="require('./assets/logos/ad_logo/master.png')"
                        alt="master card"
                        width="30"
                        v-if="masterCardDeactive"
                      >
                      </v-img>

                      <v-img
                        :src="require('./assets/logos/ad_logo/master_h.png')"
                        alt="master card"
                        width="30"
                        v-if="masterCardActive"
                      >
                      </v-img>
                    </li>

                    <li style="float:left;">
                      <v-img
                        :src="require('./assets/logos/ad_logo/visa.png')"
                        alt="visa card"
                        width="30"
                        style="margin-left:5px"
                        v-if="visaDeactive"
                      >
                      </v-img>

                    <v-img
                        :src="require('./assets/logos/ad_logo/visa_h.png')"
                        alt="visa card "
                        width="30"
                        style="margin-left:5px"
                        v-if="visaActive"
                      >
                      </v-img>
                    </li>

                    <li style="float:left;">
                      <v-img
                        :src="require('./assets/logos/ad_logo/amex.png')"
                          alt="american exprees"
                          width="30"
                          style="margin-left:5px"
                          v-if="amexDeactive"
                      >
                      </v-img>

                      <v-img
                      :src="require('./assets/logos/ad_logo/amex_h.png')"
                        alt="american exprees"
                        width="30"
                        style="margin-left:5px"
                        v-if="amexActive"
                      >
                      </v-img>
                    </li>

                    <li style="float:left;">
                      <v-img
                        :src="require('./assets/logos/ad_logo/unionpay.png')"
                          alt="unionpay Deactive"
                          width="30"
                          style="margin-left:5px"
                          v-if="unionpayDeactive"
                        >
                        </v-img>

                      <v-img
                        :src="require('./assets/logos/ad_logo/unionpay_h.png')"
                          alt="unionpay Active"
                          width="30"
                          style="margin-left:5px"
                          v-if="unionpayActive"
                        >
                        </v-img>
                    </li>

                    <li style="float:left;">
                      <v-img
                        :src="require('./assets/logos/ad_logo/diners.png')"
                          alt="diners-club"
                          width="30"
                          style="margin-left:5px"
                          v-if="dinersDeactive"
                        >
                      </v-img>

                      <v-img
                        :src="require('./assets/logos/ad_logo/diners_h.png')"
                        alt="diners-club"
                        width="30"
                        style="margin-left:5px"
                        v-if="dinersActive"
                        >
                      </v-img>
                    </li>

                   
                  </ul>
                </div> 

                <v-form ref="form_cards" style="margin-top:-10px" v-model="isFormValid" v-if="hideCard_div">
                  <v-row style="height:60px">
                    <v-col :md="colWidth" :sm="colWidth" xs="12">
                      <v-text-field
                        v-model="editedItem.card_number"
                        :rules="CardNumberRules" 
                        label="Card number"
                        oninput = "this.value = this.value.replace(/[^0-9 ]/g, '').replace(/(\..*)\./g, '$1');"
                        required
                        outlined
                        dense
                        maxlength="19"
                        class="cc-number-input"
                        v-mask="'#### #### #### ####'"
                      >
                      </v-text-field> 
                    </v-col>

                  </v-row>
                  
                  <v-row style="height:60px">
                    <v-col md="4" sm="4" xs="12">
                      <v-text-field
                        v-model="editedItem.mm"
                        label="MM"
                        :rules="[v => v.length == 2||'Month is required']" 
                        required
                        outlined
                        oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                        maxlength="2"
                        dense
                        autocomplete="off"
                        id="mm"
                      >
                      </v-text-field> 
                    </v-col>

                    <v-col md="4" sm="4" xs="12">
                        <v-text-field
                          v-model="editedItem.yyyy"
                          label="YYYY"
                          :rules="[v => v.length == 4||'Year is required']" 
                          required
                          outlined
                          oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                          maxlength="4"
                          dense
                          autocomplete="off"
                          id="yyyy"
                        >
                        </v-text-field> 
                    </v-col>

                    <v-col md="4" sm="4" xs="12" >
                        <v-text-field
                          v-model.number="editedItem.cvc_cvv"
                          label="CVC/CVV"
                          :rules="cvc_cvvRules" 
                          autocomplete="off"
                          maxlength="4"
                          oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                          required
                          outlined
                          dense 
                          id="cvc_cvv"
                        >
                        </v-text-field> 
                    </v-col>
                  </v-row>

                  <v-row style="height:60px">
                    <v-col md="12" sm="12" xs="12" >
                        <v-text-field
                          v-model="editedItem.card_holder_name"
                          label="Card Owner Name"
                          :rules="[v => !!v || 'Card Owner name is required']"
                          required
                          outlined
                          dense
                          autocomplete="off"
                          id="card_holder_name"
                        >
                        </v-text-field> 
                    </v-col>
                  </v-row>

                </v-form>

                  <div class="paynow_div" v-if="hideshowpaynow">
                     <v-row>
                      <v-col md="6" sm="6" xm="6">
                          <v-btn block depressed style="color:#fff" color="error" class="paynow_btn"
                            @click="CardPay(2)"
                            :disabled="!isFormValid"
                          > Failed</v-btn>
                       </v-col>
                       <v-col md="6" sm="6" xm="6">
                          <v-btn block depressed style="color:#fff" color="success" class="paynow_btn"
                            @click="CardPay(1)"
                            :disabled="!isFormValid"
                          > Success </v-btn>
                       </v-col>

                     </v-row>
                  </div>
              </v-container>
           </v-tabs-items>
          
            <v-tabs-items v-if="mBankingPopUpDialog" >
              <v-container grid-list-xl  wrap fluid>
                <v-form ref="mbankingref" style="margin-top:50px" v-model="isFormValid">
                  <v-row justify="center">
                    <v-col md="8" sm="12" xs="12" style="margin-top:-10px">
                      <v-text-field
                          v-model="mbankingItems.mobile_number"
                          oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                          label="Mobile Number"
                          prepend-inner-icon="mdi-cellphone-basic"
                          placeholder="e.g 01XXXXXXXXX"
                          :rules="mobileNumberRule"
                          required
                          outlined
                          dense
                          maxlength="11"
                          autocomplete="off"
                        >
                        </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row justify="center">
                    <v-col md="8" sm="12" xs="12">
                      <v-text-field
                          v-model="mbankingItems.pin_number"
                          oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                          label="Pin Number"
                          prepend-inner-icon="mdi-eye"
                          :rules="Pin_numberRule"
                          required
                          outlined
                          dense
                          autocomplete="off"
                        >
                        </v-text-field>
                    </v-col>
                  </v-row>
                </v-form>

                  <div style="margin-bottom:15px">
                     <v-row justify="center">
                      
                       <v-col md="4" sm="6" xm="6">
                          <v-btn depressed :disabled="!isFormValid" block color="error" class="success_button" @click="MobilePay(2)">Failed</v-btn>
                       </v-col>
                       <v-col md="4" sm="6" xm="6">
                          <v-btn depressed :disabled="!isFormValid" block style="color:#fff" color="#0f9750" @click="MobilePay(1)" class="paynow_btn success"
                          > Success- </v-btn>
                       </v-col>
                     </v-row>
                  </div>
              </v-container>
           </v-tabs-items>

            <v-tabs-items v-if="iBankingPopUpDialog" >
              <v-container grid-list-xl  wrap fluid>
                <v-form ref="ibankingref" style="margin-top:50px" v-model="isFormValid">
                  <v-row justify="center">
                    <v-col md="8" sm="12" xs="12" style="margin-top:-10px">
                      <v-text-field
                          v-model="ibankingItems.account_number"
                          oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                          label="Account Number"
                          prepend-inner-icon="mdi-clipboard-account"
                          :rules="AccountNumberRule"
                          required
                          outlined
                          dense
                          autocomplete="off"
                        >
                        </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row justify="center">
                    <v-col md="8" sm="12" xs="12">
                      <v-text-field
                          v-model="ibankingItems.pin_number"
                          oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                          label="Pin Number"
                          prepend-inner-icon="mdi-eye"
                          :rules="Pin_numberRule"
                          required
                          outlined
                          dense
                          autocomplete="off"
                        >
                        </v-text-field>
                    </v-col>
                  </v-row>
                </v-form>

                  <div style="margin-bottom:15px">
                     <v-row justify="center">
                       <v-col md="4" sm="6" xm="6">
                          <v-btn depressed :disabled="!isFormValid" block color="error" class="success_button" @click="iBankingPay(2)">Failed</v-btn>
                       </v-col>
                       <v-col md="4" sm="6" xm="6">
                          <v-btn depressed :disabled="!isFormValid" block @click="iBankingPay(1)" style="color:#fff" color="#0f9750"  class="paynow_btn"
                          > Success </v-btn>
                       </v-col>
                     </v-row>
                  </div>
              </v-container>
           </v-tabs-items>


          </v-tabs-items>
        </v-card>
      </div>
      <!-- Tabs or Nav section end -->

    
     <!-- Cancel Button Start -->
      <div style="margin-top:2px;padding-left:5px;padding-right:5px">
        <v-row justify="center">
          <v-col md="8" sm="12" xs="12">
              <p style="font-size:12px;margin-bottom:7px;text-align:center">
                Do you want to cancel this transaction? 
                <span @click="CancelPayment()" style="margin-left:2px;text-decoration: underline;color:#0f9750;cursor:pointer;">Click Here
                </span></p>
          </v-col>
        </v-row>
      </div>
       <!-- Cancel Button End -->

     <!-- footer section start -->
      <v-footer style="height:50px;margin-top:-16px">
        <v-col md="6" sm="6" xs="6">
          <p 
            class="text-right"
            style="font-size:14px;color:#00592B;font-family: Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif;"
          >Powered by</p>
        </v-col>

        <v-col md="6" sm="6" xs="6">
          <v-img
            :src="require('./assets/logos/sp_footer_logo.png')"
            alt="ShurjoPay Logo"
            width="77px"
            style="margin-top:-18px;margin-left:-15px"
          >
          </v-img>
        </v-col>
      </v-footer>
      <!-- footer section end -->

      
      </v-card>

    </div>

      <div v-if="isUnauthorized" :sp_return_url="sp_return_url">
          <UnauthorizedCom></UnauthorizedCom>
      </div>

    <v-main>
       
    </v-main>
  </v-app>
</template>

<script>
import UnauthorizedCom from './components/UnauthorizedCom';
import axios from "axios";

export default {
  
  name: 'App',
  components:{
    UnauthorizedCom,
  },
  
  data: () => ({
      
      
      snackbar: false,
      color: "#0F9750",
      mode: "",
      timeout: 4000,
      popup_text: "",

      /**
       * Base URL of APIs for sandbox live server
       */
     
      base_url:"https://www.sandbox.shurjopayment.com/api/",
     
      /**
       * Base URL of APIs for sandbox Localhost
       */
      //base_url:"http://localhost/sp2sandbox2/api/",

      merchant_logo:require('./assets/test_merchant_logos/shurjopay_logo.png'),
      merchant_name:"Sandbox",
      customer_name:"Customer Name",

      iBankingPopUpDialog:false,
      active_tab: '',
      card:false,
      mBankingPopUpDialog:false,
      colWidth:12,
      active_bar:null,

      card_tab:true,
      saved_card_tab1:false,
    
      isFormValid: false,
      hideCard_div:true,
      hidecardmenu:true,
      hideshowpaynow:true,
     
      masterCardActive:false,
      masterCardDeactive:true,
      visaActive:false,
      visaDeactive:true,
      amexActive:false,
      amexDeactive:true,
      unionpayActive:false,
      unionpayDeactive:true,
      dinersActive:false,
      dinersDeactive:true,

      sp_orderid:"",
      sp_amount:"10",
      sp_currency:"BDT",
      sp_token:"",

      sp_return_url:"",
      sp_cancel_url:"",
      
      isMainDiv:false,
      isUnauthorized:false,
     editedItem: {
        card_number: "",
        mm: "",
        yyyy:"",
        cvc_cvv: "",
        card_holder_name: "",
        emi: "",
      },

      others: {
        mobile_number:"",
      },

       mobileNumberRule: [
          v => !!v || "Mobile number is required",
          v => /^-?\d*$/.test(v)|| "Mobile number can only be numbers",
          v=> /^[0-9]{11}$/.test(v) ||"Mobile number must be 11 digits"
          ],

          Pin_numberRule: [
          v => !!v || "PIN number is required",
         
          ],
          AccountNumberRule:[
              v => !!v || "Account number is required",
          ],

       fullnameRules: [
         v => !!v || "Full name is required",
       ],
      
      cvc_cvvRules:[
        v => !!v || "CVV/CVC is required",
        v=> /^[0-9]{3,4}$/.test(v) ||"CVV/CVC must be at least 3 digits long"
      ],
      CardNumberRules:[
        v => !!v || "Card number is required",
        v => v.length >14 ||'Card number must be at least 13 digits long',
        //v =>  v.isSelectedCard == false || 'Enter a valid card number'
      ],

      user_entered_card_name:"",
      isSelectedCard:false,
     
      /*Tabs visibility Dynamic */
      m_store_id:0,
      dwidth:"",

      mbankingItems:{
        mobile_number: "",
        pin_number:"",
      },

       ibankingItems:{
        account_number: "",
        pin_number:"",
      },
  }),

  created(){
     //the function which need before mounted we will inject here
  },

  mounted() {
    this.active_tabs(1);
    this.sp_orderid = this.$route.query.order_id;
    this.sp_token = this.$route.query.token;
     if(this.sp_token.length !=0){
        const options = {
            method: 'POST',
            url: this.base_url+'chk_token',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            }
            };
            axios.request(options).then(response =>{
                if(response.data.message== 'ok'){    
                  this.isMainDiv = true;
                  this.isUnauthorized = false; 
              }

            }).catch(error =>{
                if (error.response.status == 401) {
                    this.isMainDiv = false;
                    this.isUnauthorized = true; 
                  }
            });
      }
      /*Wait 500 ms or 1/2 seconds then call*/
      setTimeout(this.check_order, 500);
  },

  computed:{
    //computed properties
  },

  watch:{
    "mbankingItems.mobile_number":function(val){
        var length = val.length;
        if(length == 2){
          if(val != '01'){
            this.mbankingItems.mobile_number = "01";
          }
        }
        if(length==11){
          var given_input = val;
          var firstTwoChar = given_input.substring(0, 2);
          var restChar = given_input.substring(2, 11);
            if(firstTwoChar != '01'){
              this.mbankingItems.mobile_number = "01"+restChar;
            }
        }
    },

     "editedItem.mm":function(val){
       
        if(val.length ==2){
          var first_digit =parseInt(val.charAt(0));
          var second_digit =parseInt(val.charAt(1));
          if(first_digit == 1){
            if(second_digit == 3 || second_digit ==4 || second_digit==5 || second_digit==6 || second_digit==7 || second_digit==8|| second_digit==9){
               this.editedItem.mm = first_digit;
            }
          }

          if(first_digit == 0){
            if(second_digit == 0){
               this.editedItem.mm = first_digit;
            }
          }
          if(first_digit >1){
            this.editedItem.mm="";
          }
        }
    },

     "editedItem.yyyy":function(val){
       var year_length = val.length;

       if(year_length==2){
         var last_two_digit = new Date().getFullYear().toString().substr(-2);
         var first_two_digit = new Date().getFullYear().toString().substr(0,2);
         var inputed_year = this.editedItem.yyyy;
          if(inputed_year>=last_two_digit){
             this.editedItem.yyyy = first_two_digit + inputed_year;
          }
        }

        if(year_length==4){
        let current_year = new Date().getFullYear().toString();
        let inputed_year = this.editedItem.yyyy;
          if(inputed_year < current_year ){
            this.editedItem.yyyy ="";
          }
          //document.getElementById("cvc_cvv").focus();
       }
     },

     "editedItem.card_number":function(val){
  
          var inputlen = val.length;
          if(inputlen==0 || inputlen ==1){
            this.masterCardDeactive=true;
            this.masterCardActive=false;
            this.visaDeactive=true;
            this.visaActive=false;
            this.amexDeactive=true;
            this.amexActive=false;
            this.unionpayDeactive=true;
            this.unionpayActive=false;
            this.dinersDeactive=true;
            this.dinersActive=false;
            this.isSelectedCard = false;
          }
          
          if(inputlen==2){
            this.select_card_icon(val);
          }

          if(inputlen >11){
            this.select_card_icon(val);
          }
     },
          
  },

  methods: {
    CancelPayment(){
      var order_id = this.$route.query.order_id;
      var sp_token = this.$route.query.token;
      const options = {
        method: 'POST',
        url: this.base_url+'canceled',
        headers: {
          Authorization: 'Bearer '+ sp_token
        },
        data: {
          order_id
          },
        };

        axios.request(options).then(response =>{
          if(response.data.message==1){
            window.location.href = response.data.url;
          }
          else{
            this.snackbar = true;
            this.color = "error";
            this.timeout =2000;
            this.popup_text = "Something Went Wrong...";
          }
        }).catch(error =>{
            //console.log(error);
            this.snackbar = true;
            this.color = "error";
            this.timeout =2000;
            this.popup_text = "Something Went Wrong...";
        });
    },

    check_order(){
       var orderid = this.$route.query.order_id;
       var sp_token = this.$route.query.token;
       const options = {
          method: 'POST',
          url: this.base_url+'chk-order',
          headers: {
            Authorization: 'Bearer '+ sp_token
          },
          data: {
            orderid
           },
          };

          axios.request(options).then(response =>{
            this.sp_amount = response.data[0].payable_amount;
            this.sp_currency = response.data[0].currency;
            this.m_store_id = response.data[0].store_id;
            this.sp_return_url = response.data[0].return_url;
            this.sp_cancel_url = response.data[0].cancel_url;
            this.merchant_info(response.data[0].store_id);
          }).catch(error =>{
             console.log(error);
          });
    },

    active_tabs(index){
      if(index==1){
        this.active_tab = 'cards';
        this.show_cards();
      }
      else if(index==2){
        this.active_tab = 'mfs';
        this.show_mbanking();
      }
      else{
       this.active_tab = 'ibanking';
        this.show_ibanking();
      }
    },

    show_cards(){
      this.mBankingPopUpDialog = false;
      this.iBankingPopUpDialog = false;
      this.saved_card_tab1 = true; 
      this.hidecardmenu = true;
    },

    show_mbanking(){
      this.iBankingPopUpDialog = false;
      this.saved_card_tab1 = false;
      this.mBankingPopUpDialog = true;
      this.hidecardmenu = false;
    },

    show_ibanking(){
      this.saved_card_tab1 = false;
      this.mBankingPopUpDialog = false;
      this.iBankingPopUpDialog = true;
      this.hidecardmenu = false;
    },

    
    select_card_icon(val){
        let first_digit = val.charAt(0);
        let second_digit = val.charAt(1);
        let first_two_digit = val.substring(0, 2)
        
        if(first_two_digit==34 || first_two_digit==37 ){
          this.amexActive = true;
          this.amexDeactive = false;
          this.user_entered_card_name ="amex";
          this.isSelectedCard = true;
        }

        if(first_digit== 3 && (second_digit ==0 || second_digit==6 || second_digit==8)){
            this.dinersActive = true;
            this.dinersDeactive = false;
            this.user_entered_card_name = "dinners";
            this.isSelectedCard = true;
        }

        if(first_digit==4){
          this.visaDeactive=false;
          this.visaActive=true;
          this.user_entered_card_name="visa";
          this.isSelectedCard = true;
        }

        if((first_digit==5 && (second_digit>=1 && second_digit<=5)) || 
        (first_digit==2 && (second_digit>=2 && second_digit<=7))){
          this.masterCardDeactive=false;
          this.masterCardActive=true;
          this.user_entered_card_name="master";
          this.isSelectedCard = true;
        }

        if(first_digit==6){
          this.unionpayDeactive=false;
          this.unionpayActive=true;
          this.user_entered_card_name="union";
          this.isSelectedCard = true;
        }

        if(first_digit==0 ||first_digit==1 ||first_digit==7 ||first_digit==8 ||first_digit==9 ){
          this.snackbar = true;
          this.color = "red";
          this.timeout=2000;
          this.popup_text = "Enter a valid card number.";
          this.isSelectedCard = false;
          this.masterCardDeactive=true;
          this.masterCardActive=false;
          this.visaDeactive=true;
          this.visaActive=false;
          this.amexDeactive=true;
          this.amexActive=false;
          this.unionpayDeactive=true;
          this.unionpayActive=false;
          this.dinersDeactive=true;
          this.dinersActive=false;
        }
    },


    /**
     * CardPay Function Start
     */
    CardPay(statusValue){
      let selectedCardStatus = this.isSelectedCard;
      if(selectedCardStatus === true){
        var card_number = (this.editedItem.card_number).replace(/ /g, "");
        var exmonth = this.editedItem.mm;
        var exyear = this.editedItem.yyyy;
        var cvv_cvc = (this.editedItem.cvc_cvv).toString();
        var amount = this.sp_amount;
        var order_no = this.$route.query.order_id;
        var card_holder_name = this.editedItem.card_holder_name;
        var card_name = this.user_entered_card_name;
        var status = statusValue;
        var store_id = this.m_store_id;

        var sp_token = this.$route.query.token;
        const options = {
            method: 'POST',
            url: this.base_url+'card-pay',
            headers: {
              Authorization: 'Bearer '+ sp_token
            },
            data: {
              card_number,
              exmonth,
              exyear,
              cvv_cvc,
              amount,
              order_no,
              card_holder_name,
              card_name,
              status,
              store_id
            },
            };
            axios.request(options).then(response =>{
              if(response.data.message==1){
                window.location.href = response.data.url;
              }
              else{
                this.snackbar = true;
                this.color = "red";
                this.timeout = 4000;
                this.popup_text = "Something went wrong.";
              }
            }).catch(error =>{
              console.log(error);
              this.snackbar = true;
              this.color = "red";
              this.timeout = 4000;
              this.popup_text = "Something went wrong.";
          });
      } //end if

      else if(selectedCardStatus === false){
        this.snackbar = true;
        this.color = "red";
        this.timeout = 4000;
        this.popup_text = "Enter a Valid Card number";
        this.editedItem.card_number="";
      }  

    }, 
    

    /**
     * MobilePay Function Start
     */
    MobilePay(statusValue){
      let checkEmpty= this.$refs.mbankingref.validate();

      if(checkEmpty){
        var pin = this.mbankingItems.pin_number;
        var order_no = this.$route.query.order_id;
        var amount = this.sp_amount;
        var store_id = this.m_store_id;
        var mobile_no = this.mbankingItems.mobile_number;
        var status = statusValue;

        var sp_token = this.$route.query.token;
        const options = {
            method: 'POST',
            url: this.base_url+'mobile-pay',
            headers: {
              Authorization: 'Bearer '+ sp_token
            },
            data: {
              pin,
              order_no,
              amount,
              store_id,
              mobile_no,
              status,
            },
            };
            axios.request(options).then(response =>{
               if(response.data.message==1){
                window.location.href = response.data.url;
              }
              else{
                this.snackbar = true;
                this.color = "red";
                this.timeout = 4000;
                this.popup_text = "Something went wrong.";
              }
            }).catch(error =>{
              console.log(error);
              this.snackbar = true;
              this.color = "red";
              this.timeout = 4000;
              this.popup_text = "Something went wrong.";
          });
       }//end if
     },


     /**
     * iBankingPay Function Start
     */
    iBankingPay(statusValue){
      let checkEmpty= this.$refs.ibankingref.validate();

      if(checkEmpty){
        var pin = this.ibankingItems.pin_number;
        var order_no = this.$route.query.order_id;
        var amount = this.sp_amount;
        var store_id = this.m_store_id;
        var account_no = this.ibankingItems.account_number;
        var status = statusValue;

        var sp_token = this.$route.query.token;
        const options = {
            method: 'POST',
            url: this.base_url+'ibanking-pay',
            headers: {
              Authorization: 'Bearer '+ sp_token
            },
            data: {
              pin,
              order_no,
              amount,
              store_id,
              account_no,
              status,
            },
            };
            axios.request(options).then(response =>{
               if(response.data.message==1){
                window.location.href = response.data.url;
              }
              else{
                this.snackbar = true;
                this.color = "red";
                this.timeout = 4000;
                this.popup_text = "Something went wrong.";
              }
            }).catch(error =>{
              console.log(error);
              this.snackbar = true;
              this.color = "red";
              this.timeout = 4000;
              this.popup_text = "Something went wrong.";
          });
       }//end if
     },
   
  },

}
</script>

<style scoped>

  .page_bg{
    background-color: #f2f2f2 !important;
  }

  .text_style{
    font-size:13px;
    font-family: Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif;
    margin-right:-10px;
  }
  .paynow_div{
    margin-left:0px;
    margin-right:0px;
    min-height:70px;
    margin-top:14px;
  }

  .main_div{
    margin-top:-30px;
    
  }
   
  .card_style{
      background-color:#ffffff;
      margin-top:100px !important;
  }

  .avatar_div{
    background-color: #fff;
    border-radius:15px 15px 0px 0px !important;
    height:56px;
  }

  .avatar_img{
      display: block;
      border-radius: 100%;
      margin: 4px;
      border: 4px solid #0F9750;
    }

  .payment_to{
      font-size: 18px;
      font-weight: bold;
      font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
      color:#0F9750;
      margin-top:5px;
  }
 
  .client_name{
      font-size: 14px;
      font-weight: normal;
      font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
      color:#0F9750;
      margin-top:-18px;
  }

  .payment_amount{
      font-size: 18px;
      font-weight: bold;
      font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
      color:#0F9750;
      margin-top:-13px;
  }

  .order_id{
      font-size: 13px;
      font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
      color:#0F9750;
      margin-top:-18px;
  }

  .pay_amount_main_div{
      background-color:#f2f2f2;
      height: 60px;
      margin:5px;
      border: 2px solid #f2f2f2;
      padding:5px;
   
   }

   .border_left{
     border-left: 2px dotted #000000;
     height: 40px;
     margin-top: 22px;
   }

   .paynow_btn{
     font-size: 16px;
     font-weight: bolder;
     font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
     min-height:40px !important;
     text-transform: uppercase;
   }

   .success_button{
     font-size: 16px;
     font-weight: bolder;
     font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
     min-height: 40px !important;
     text-transform: uppercase;
   }
   .tabs_text_style{
     text-transform: none;
     color:#fff !important;
     font-size: 14px;
     background-color:#0F9750;
     border-radius:5px;
     margin-right:3px; 
     font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
   }
   .lasttabs_text_style{
     text-transform: none;
     color:#fff !important;
     font-size: 14px;
     background-color:#0a867b;
     border-radius:5px;
     margin-right:0px;
     font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;

   }
   
   /* override default properties of v-tabs */
   .v-tabs-slider{
     color:#df2c2c !important;
     border-radius:10px;
   }

   @media only screen and (max-width: 403px) {

    .tabs_text_style {
      font-size: 10px;
      background-color:#0F9750;
      border-radius:3px;
      margin-right:2px;

    }

    .lasttabs_text_style{
     font-size: 10px;
     background-color:#0F9750;
     border-radius:3px;
     margin-right:0px;
   }
   
   #card_style,#mbanking_style,#ibanking_style,#account_style{
     width: 90px;
     padding: 0px;
   }
  }


  @media only screen and (max-width: 422px) {
    .payment_to{
        font-size: 16px;
    }

    .client_name{
        font-size: 13px;
     }

     .payment_amount{
        font-size: 15px;
      } 

    .order_id{
        font-size: 11px; 
    }
    .avatar_img{
        display: block;
        border-radius: 100%;
        margin: 4px;
        border: 4px solid #0F9750;
      }
   }
</style>


